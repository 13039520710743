.App {

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.App-logo {
  width: 100%;
  max-width: 100vw;
  z-index: 1;

}


.no-underline {
  text-decoration: none;
  /* Désactive le soulignement */
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.h1 {

  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: 400;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;

}

.MenuText {
  color: rgb(27, 27, 27);
  font-size: 20;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  background-color: none;
}

.Link {
  text-decoration: none;
  text-decoration-line: underline;

}

.Menu {

  margin-right: 10px;
  padding-top: 5px;
  padding-right: 7px;
  padding-bottom: 5px;
  border-radius: 10px;
  width: 100px;
  border-width: 1px;

  background-color: none;
  color: rgb(27, 27, 27);
  font-size: 20;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  background-color: none;
}

/*
.social{
  height:30px;
   width:30px; 
   padding-right:10px;
   padding-left:10px;
}*/
.subDiv {

  display: flex;
  flex-direction: column;
}

.restaurantView {

  align-items: "center";
  display: "flex";
  width: "80%";
  flex-direction: row;
}

/*
@media (max-width: 200px) {
  /* Styles pour les écrans plus petits (largeur max de 768px) 
  .restaurantView {
    flex-direction: column;
  }
}*/

.restaurantView {

  flex-direction: column;
}

.origin {
  width: 100%;
  height: 100vh;
  /* 100% de la hauteur de la vue */
  background-color: #caab8f;
  overflow-y: auto;
  /* Ajoute une barre de défilement si nécessaire */
}

.gallery-wrapper {
  width: 80%;
  margin: 0 auto;
  height: 100vh;
  /* 100% de la hauteur de la vue */
  background-color: rgb(27, 27, 27);
  overflow-y: auto;
  /* Ajoute une barre de défilement si nécessaire */
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .gallery-wrapper {
    width: 95%;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  /* Marge entre les images */
  padding: 16px;
  /* Ajoute de la marge à l'intérieur de la galerie */
}

.description {
  color: white;
  text-align: center;
  font-family: -moz-initial;

}

.gallery-item {
  overflow: hidden;
}

.img {
  width: 100%;
  aspect-ratio: 1;
  display: block;
}

@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 601px) and (max-width: 100vw) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    max-width: 60vw;
    /* Limite le nombre maximum de colonnes à 3 */
  }
}


.accueil-container {
  max-width: 100%;
  margin: 0 auto;
}

.app-header {
  text-align: center;
}

.app-logo {
  width: 100%;
  height: auto;
  border-bottom-color: rgb(27, 27, 27);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  z-index: 1;
  margin-bottom: 1px;
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  z-index: 0;
}

.presentation {
  width: 100%;
  max-width: 100vw;
  display: block;
  background-color: #caab8f;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
  display: flex;
}

.presentation-image {
  margin-left: 10%;
  max-width: 35%;
  max-height: 200px;
}

.restaurant-title {
  font-weight: 500;
  margin-right: 15%;
  margin-left: 15%;
  font-size: 40px;
  color: rgb(27, 27, 27);
  margin-bottom: 0px;
}

.restaurant-description {
  margin-top: 10px;
  margin-right: 15%;
  margin-left: 15%;
  text-align: justify;

}

.menu-links {
  margin-top: 10px;
  margin-left: 15%;
}

.menu-link {
  margin-right: 10px;
  margin-left: 10px;
}

.informations-container {
  background-color: rgb(27, 27, 27);
  color: white;
  padding: 25px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;



}

.informations {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.info-column {
  flex: 1 1 45%;
  margin: 10px 0;
}

.info-title {
  font-weight: 700;
}

.info-detail {
  margin-top: 5px;
}

.highlight {
  font-weight: 600;
}

.follow-text {
  margin-top: 20px;
  font-weight: 700;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social {
  width: 40px;
  height: 40px;
}